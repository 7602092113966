import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { alertHtmlMessage, alertMessage, notofication_forcash } from '../../Functions/Functions';
import { getPaidCourseCartItems, getPaidExamCartItems, getUserInfo, getUserToken, removePaidCourseCartItem, removePaidExamCartItem } from '../../Session/Session';
import axios from '../../Config/axios';
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../BreadcrumbComponent/BreadcrumbComponent';
import Loader from '../../Loader/Loader';
import './CourseCheckout.css';
const CourseCheckout = () => {
    const userInfo = getUserInfo();
    const navigate = useNavigate();
    let [cartItems, setCartItems] = useState([]);
    const [errors, setErrors] = useState([]);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [method, setMethod] = useState('Payment');
    const [numberHide, setNumberHide] = useState('d-block');
    const [paymentType, setPaymentType] = useState(method);
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState('');
    const [areas, setAreas] = useState([]);
    const [orderLoading, setOrderLoading] = useState(false);
    const [course, setCourse] = useState([]);
    const [isName, setIsName] = useState(true);
    const [url, seturl] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([
        { name: 'Home', "url": '/', active: false },
        { name: 'Course', "url": '/courses', active: false },
    ]);
    if (getUserToken() === false) {
        navigate('/login')
    }

    const getDistrict = async () => {
        try {
            const res = await axios.get('/district');
            setDistricts(res.data.data);
        } catch (error) {
            alertMessage('error', 'Error', error.message);
        }
    }
    const getArea = async (id) => {
        try {
            const res = await axios.get(`/sub-district/${id}`);
            setAreas(res.data.data);
        } catch (error) {
            alertMessage('error', 'Error', error.message);
        }
    }
    const handelArea = (e) => {
        let districId = e.target.value;
        let textContent = e.target.selectedOptions[0].text;
        if (districId != '') {
            setDistrict(districId);
            getArea(districId)
        } else {
            setAreas([]);
        }
    }
    const getCourse = async (id) => {
        try {
            const res = await axios.get(`/web/paid-course-details/${id}`);
            console.log('res', res.data.data)
            setCourse(res.data.data.course);
            setBreadcrumbs([
                ...breadcrumbs,
                { name: res.data.data.course.name, "url": `/course-detail/${res.data.data.course.id}`, active: false },
                { name: 'Checkout', "url": `#`, active: true },
            ]);
            setIsName(false);
        } catch (error) {
            alertMessage('error', 'Error', error.message);
        }
    }
    useEffect(() => {
        document.title = "Checkout | Nahid24.com"
        setCartItems(getPaidCourseCartItems());
        getDistrict();
        getCourse(getPaidCourseCartItems().id);
    }, []);

    const handelFormSubmit = async (e) => {
        e.preventDefault();
        setOrderLoading(true);
        seturl(false);
        setErrors([]);
        let orderItem = cartItems;
        if (orderItem) {
            let payment_number = e.target.payment_number.value;
            if (e.target.payment_method.value == '') {
                alertMessage('error', 'Error', 'Payment Method is required!');
                setOrderLoading(false);
                return false;
            }
            if (e.target.payment_method.value == 'Cash On Delivery') {
                payment_number = '';
            } else {
                if (!payment_number) {
                    alertMessage('error', 'Error', 'Payment Number is required!');
                    setOrderLoading(false);
                    return false;
                }
            }
            let paymentInfo = {
                product_info: orderItem,
                name: e.target.name.value,
                number: e.target.number.value,
                address: e.target?.full_address?.value,
                // address:inputAddress,
                payment_type: e.target.payment_method.value,
                payment_number: payment_number,
                amount: course.offer_price > 0 ? course.offer_price : course.price,
                district: district,
                area: e.target?.sub_district?.value,
                // note:e.target.note.value,
            };

            const token = getUserToken();

            try {
                const res = await axios.post('/web/paid-course/order', paymentInfo, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setOrderLoading(false);
                if (res.data.error === false) {
                    removePaidCourseCartItem();
                    alertHtmlMessage('success', 'Success', res.data.message);
                    navigate('/my-courses');
                } else {
                    if (res.data.url != undefined) {
                        seturl(true);
                    }
                    let errorMessageHml = '';
                    const errorMessages = res.data.message;

                    errorMessages.forEach((errorItem, index) => {
                        errorMessageHml += `<p class="text-danger text-center m-0">${errorItem}</p>`;
                    });
                    alertHtmlMessage('error', 'Error', errorMessageHml)
                    // setErrors(res.data.message);
                }
            } catch (error) {
                alertMessage('error', 'Error', error.message)
            }
            setOrderLoading(false);
        } else {
            alertMessage('error', 'Error', 'Please Fillup all field!');
            setOrderLoading(false);
        }
    }
    return (
        <Container>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            <div className="checkout-section">
                <Row>
                    <Col md={8}>
                        <div className="shipping-form">
                            <Form onSubmit={handelFormSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Label>Name
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control type="text" defaultValue={userInfo.name} name="name" placeholder="Full Name" />
                                            <Form.Text className="text-danger error-text error_name">

                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="phone">
                                            <Form.Label>Mobile Number
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control type="text" defaultValue={userInfo.number} name="number" placeholder="Mobile Number" />
                                            <Form.Text className="text-danger error-text error_number"></Form.Text>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={6}>
                                        <Form.Group className="mb-3" controlId="zilla">
                                            <Form.Label>Zilla/District
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Select name="district" id="district" onChange={handelArea}>
                                                <option value="">Select District</option>
                                                {
                                                    districts.map((district) => { return (<option key={district.id} value={district.id}>{district.district}</option>) })
                                                }

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> */}
                                    {/* <Col md={6}>
                                        <Form.Group className="mb-3" controlId="sub_district">
                                            <Form.Label>Area/Upazila/Sub District
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Select name="sub_district" id="sub_district">
                                                <option value="">Select Area</option>
                                                {
                                                    areas.map((area) => { return (<option key={area.id} value={area.id}>{area.sub_district}</option>) })
                                                }

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> */}
                                    {/* <Col md={6}>
                                        <Form.Group className="mb-3" controlId="full_address">
                                            <Form.Label>Full Address

                                            </Form.Label>
                                            <Form.Control type="text" name="full_address" placeholder="Full Address" />
                                            <Form.Text className="text-danger error-text error_full_address">

                                            </Form.Text>
                                        </Form.Group>
                                    </Col> */}
                                    {/* <Col md={6}>                         
                                        <Form.Group className="mb-3" controlId="address">
                                            <Form.Label>Order Note
                                                
                                            </Form.Label>
                                            <Form.Control as="textarea" name="note" placeholder="Your note" />
                                            <Form.Text className="text-danger error-text error_note"></Form.Text>
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="amount" >
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control type="text" readOnly name="amount" value={course.offer_price > 0 ? course.offer_price : course.price} placeholder="Amount" />
                                            <Form.Text className="text-danger error-text error_amount"></Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">

                                            <Form.Label>
                                                Payment Method
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Select name="payment_method">
                                                <option value="">Select Payment Method</option>
                                                <option value="Bkash">Bkash</option>
                                                <option value="Nagad">Nagad</option>
                                                <option value="Rocket">Rocket</option>
                                            </Form.Select>
                                            <Form.Text className="text-danger error-text error_payment_type"></Form.Text>
                                            <Form.Text className="text-danger error-text error_payment_type"></Form.Text>
                                            {
                                                method == 'Bkash' || method == 'Nagad' || method == 'Rocket' ?
                                                    <p className='payment-bold'>
                                                        {
                                                            method != 'Payment' && method != '' ?
                                                                `01787943429 Personal নাম্বারে ${course.offer_price > 0 ? course.offer_price : course.price} টাকা ${method} এ send money করুন। Send Money করার পর নিচের বক্সে আপনার ${method} Number দিন।`
                                                                :
                                                                `01787943429 Personal নাম্বারে ${course.offer_price > 0 ? course.offer_price : course.price} টাকা send money করুন।`
                                                        }
                                                    </p>
                                                    :
                                                    ''
                                            }
                                            {/* <p className='payment-bold'>
                                                01787943429 Personal নাম্বারে <b>{course.price} টাকা</b> send money করুন
                                            </p> */}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className={`mb-3 ${numberHide}`} controlId="payment_number">
                                            <Form.Label>Payment Number</Form.Label>
                                            <Form.Control type="text" name="payment_number" placeholder={`Your ${method} Number`} />
                                            <Form.Text className="text-danger error-text error_payment_number"></Form.Text>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                {
                                    numberHide === 'd-none' ?
                                        <div>
                                            <Alert variant="warning">
                                                Cash on Delivery তে অর্ডার করলে আপনি সরাসরি Nahid24 থেকে বইগুলো পাবেন না। আমরা একটি 3rd Party এর মাধ্যমে আপনাকে বইগুলো পাঠাবো। এজন্য Delivery Time ৩-৭ দিন লাগতে পারে। বই হাতে পাওয়ার পর Delivery Man কে {course.offer_price > 0 ? course.offer_price : course.price} টাকা দিবেন।
                                            </Alert>
                                        </div>
                                        :
                                        <div>
                                            <Alert variant="warning">
                                                01787943429  Personal নাম্বারে {course.offer_price > 0 ? course.offer_price : course.price} টাকা send money করার পর ভর্তি হন বাটনে ক্লিক করুন। Send Money না করে ভর্তি হন বাটনে Click করলে আপনার ভর্তি approve হবেনা।
                                            </Alert>
                                        </div>
                                }
                                {

                                    errors.length > 0 ?
                                        <div className="alert alert-warning" role="alert">
                                            {errors.map((errorItem, index) => { return (<p key={index} className="text-danger text-start m-0">{errorItem}</p>) })}
                                        </div>
                                        : ''
                                }
                                <div className='placeorder_btn text-end paid-checkout-buttons'>
                                    {
                                        orderLoading ?
                                            <Button className="py-2 px-3" variant="primary" type="button">
                                                <Loader />
                                            </Button>
                                            :
                                            <>
                                                {
                                                    url ? <Link to="/my-courses" className="py-2 px-3 me-2 btn btn-primary" >
                                                        My Paid Courses
                                                    </Link> : ''
                                                }
                                                <Button className="py-2 px-3" variant="primary" type="submit" >
                                                    ভর্তি হন
                                                </Button>
                                            </>
                                    }
                                </div>

                            </Form>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='checkout-summary'>
                            <h3>Checkout Summary</h3>
                            <div className="summary-content">
                                <div className='summery-item'>
                                    <span>Total</span>
                                    <span>{course.offer_price > 0 ? course.offer_price : course.price}Tk</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Successfull</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        Thank You. Your order has been received.Your order id is 3.15.You will received the books by courier service within 3-5 days.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Receipt
                        </Button>
                        <Button variant="success"><Link className='modal-btn' to="/my-order">My All Order</Link></Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Container>
    );
};

export default CourseCheckout;