
const getCartItems = () => {
  const exists = localStorage.getItem('shopping_cart');
  return exists ? JSON.parse(exists) : false;
}
const updateCartItem = cart => {
  localStorage.setItem('shopping_cart', JSON.stringify(cart));
}
const setUserInfo = user => {
  localStorage.setItem('user_info', JSON.stringify(user));
}
const getUserInfo = () => {
  const userInfo = localStorage.getItem('user_info');
  return userInfo ? JSON.parse(userInfo) : false;
}
const setUserToken = token => {
  localStorage.setItem('user_token', JSON.stringify(token));
}
const getUserToken = () => {
  const userToken = localStorage.getItem('user_token');
  return userToken ? JSON.parse(userToken) : false;
}
const removeUserToken = () => {
  localStorage.removeItem('user_token');
  localStorage.removeItem('user_info');
}
const removeCartItem = () => {
  localStorage.removeItem('shopping_cart');
}
const setPaidExamCartItems = cart => {
  localStorage.setItem('paid_exam_cart', JSON.stringify(cart));
}
const getPaidExamCartItems = () => {
  const exists = localStorage.getItem('paid_exam_cart');
  return exists ? JSON.parse(exists) : false;
}
const removePaidExamCartItem = () => {
  localStorage.removeItem('paid_exam_cart');
}

const setPaidCourseCartItems = cart => {
  localStorage.setItem('paid_course_cart', JSON.stringify(cart));
}
const getPaidCourseCartItems = () => {
  const exists = localStorage.getItem('paid_course_cart');
  return exists ? JSON.parse(exists) : false;
}
const removePaidCourseCartItem = () => {
  localStorage.removeItem('paid_course_cart');
}

export {
  getCartItems,
  updateCartItem,
  setUserInfo,
  getUserInfo,
  setUserToken,
  getUserToken,
  removeUserToken,
  removeCartItem,
  setPaidExamCartItems,
  getPaidExamCartItems,
  removePaidExamCartItem,
  setPaidCourseCartItems,
  getPaidCourseCartItems,
  removePaidCourseCartItem
}