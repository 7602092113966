import React from 'react';
import { Link } from 'react-router-dom';
import './MyCourseItem.css';
const MyCourseItem = ({ exam }) => {
    const { id, image, title, status } = exam;
    return (
        <div className="py-3 col-lg-4 col-md-6">
            {
                status === 1 ?
                    <Link to={`/my-course/${id}`} className="text-decoration-none category-link">
                        <div className="course-card-wrapper">
                            <div className="card-img-wrapper">
                                <Link to={`/my-course/${id}`}>
                                    <img src={image} alt={title} />
                                </Link>
                            </div>
                            <div className='p-3 pb-2'>
                                <h3>{title}</h3>
                            </div>
                            <span className="course-status">Admitted</span>
                        </div>
                    </Link>
                    :
                    <div className="course-card-wrapper">
                        <div className="card-img-wrapper">
                            <img src={image} alt={title} />
                        </div>
                        <div className='p-3 pb-2'>
                            <h3>{title}</h3>
                        </div>
                        
                        <span className="course-status">{status == 0 ? 'Processing' : ((status == 3) ? 'Cancelled' : '')}</span>
                    </div>
            }

        </div>
    );
};

export default MyCourseItem;