// YouTubePlayer.js
import React, { useEffect, useState, useRef } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import './YouTubePlayer.css';

const YouTubePlayer = ({ videoId }) => {
  const [isMounted, setIsMounted] = useState(false);
  const playerWrapperRef = useRef(null);
  console.log('videoId', videoId)
  useEffect(() => {
    setIsMounted(true); // Set to true after component mounts
  }, []);

  const addOverlay = () => {
    const iframe = playerWrapperRef.current?.querySelector('iframe');
    if (iframe) {
      // Create the overlay element if it doesn't exist
      if (!iframe.parentElement.querySelector('.iframe-overlay')) {
        const overlay = document.createElement('div');
        overlay.className = 'iframe-overlay';
        overlay.style.position = 'absolute';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.backgroundColor = 'transparent' // Semi-transparent overlay
        overlay.style.pointerEvents = 'none'; // Allow interaction with the video
        overlay.style.zIndex = '100';

        // Append overlay to the same parent as the iframe
        iframe.parentElement.appendChild(overlay);
      }
    }
  };


  useEffect(() => {
    if (isMounted) {
      addOverlay();
    }
  }, [isMounted]);

  if (!isMounted) return null; // Don't render player until mounted

  return (
    <div ref={playerWrapperRef} style={styles.playerWrapper}>
      {/* Plyr Player */}
      <Plyr
        source={{
          type: 'video',
          sources: [
            {
              src: videoId,
              provider: 'youtube',
            },
          ],
        }}
        options={{
          autoplay: true,
          controls: [
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'settings',
            'fullscreen',
          ],
          youtube: {
            noCookie: true,       // Use YouTube's privacy-enhanced mode
            rel: 0,               // Hide related videos after playback
            modestbranding: 1,    // Minimize YouTube branding (removes large YouTube logo)
          },
        }}
      />
    </div>
  );
};


const styles = {
  playerWrapper: {
    position: 'relative',
    width: '100%',
    // maxWidth: '640px',
    height: 'auto',
    overflow: 'hidden', // Ensure the overlay stays within bounds
    borderRadius: '15px'
  },
};

export default YouTubePlayer;
