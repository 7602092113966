import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alertMessage } from '../../Functions/Functions';
import { getUserToken } from '../../Session/Session';
import axios from '../../Config/axios';
import './MyCourses.css';
import Loader from '../../Loader/Loader';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../BreadcrumbComponent/BreadcrumbComponent';
import MyCourseItem from './MyCourseItem/MyCourseItem';
const MyCourses = () => {
    const [paidExams, setPaidExams] = useState([]);
    const [isPaidExam, setIsPaidExam] = useState(true);
    const navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = useState([
        { name: 'Home', "url": '/', active: false },
        { name: 'Profile', "url": '/profile', active: false },
        { name: 'My Paid Courses', "url": '/my-courses', active: true },
    ]);
    const getPaidExams = async () => {
        try {
            const token = getUserToken();
            const res = await axios.get('/web/my-courses', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setPaidExams(res.data.data);
            setIsPaidExam(false);
        } catch (error) {
            alertMessage('error', 'Error', error.message);
        }
    }
    useEffect(() => {
        document.title = "My Paid Courses | Nahid24.com";
        getPaidExams();
    }, []);
    if (isPaidExam) {
        return (
            <div className='books-section'>
                <Loader />
            </div>

        );
    }
    return (
        <Container>
            <h2 className="text-center my-2 page-title">পেইড কোর্স </h2>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            <div className="">
                <Row className="py-3">
                    {
                        paidExams.length > 0 ? paidExams.map((exam) => { return (<MyCourseItem key={exam.id} exam={exam} />) })
                            :
                            <Col md={12}>
                                <div class="alert alert-warning text-center" role="alert">
                                    আপনি কোন পরীক্ষার ব্যাচে ভর্তি হন নাই।
                                </div>
                            </Col>
                    }
                </Row>
            </div>
            {
                paidExams.length > 0 ?
                    <div className='videotutorial'>
                        <Row className="videoContent">
                            <Col md={6}>
                                <div className='video'>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/TO4gZJsyHVs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='guid-content'>
                                    <h1>যারা ভর্তি হয়েছেন</h1>
                                    <h3>তাদের জন্য কিছু কথা</h3>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div className='videotutorial'>
                        <Row className="videoContent">
                            <Col md={6}>
                                <div className='video'>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/lF_gloGGLCk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='guid-content'>
                                    <h2>কীভাবে ভর্তি হবেন?</h2>
                                    <p>Exam Batch কীভাবে ভর্তি হবেন তা বুঝতে পারছেন না?</p>
                                    <p>তাহলে পাশের Video Tutorial টি দেখতে পারেন। </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
            }

        </Container>
    );
};

export default MyCourses;