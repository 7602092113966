import React from "react";
import "./CoursesIndex.css";
import { Link } from "react-router-dom";
const CourseItem = ({ course }) => {
    const {
        id,
        title,
        type,
        description,
        duration,
        instractors,
        video_quantity,
        exam_quantity,
        pdf_quantity,
        start_date,
        price,
        offer_price,
        url,
        extra_url,
        image,
        file,
        students
    } = course;
    return (
        <div className="py-3 col-lg-4 col-md-6">
            <div className="course-card-wrapper">
                <div className="card-img-wrapper">
                    <Link to={`/course-detail/${id}`}>
                        <img src={image} alt={title} />
                    </Link>
                </div>
                <div className="p-3 pb-0">
                    <Link to={`/course-detail/${id}`} className="course-card-title">{title}</Link>
                    <div>
                        <div className="d-flex justify-content-between align-items-center pt-3">
                            {
                                students ?
                                    <div className="state_item">
                                        <div className="state_item_item" title="Registered Student">
                                            <span>
                                                <i class="fas fa-user-plus"></i> {students}
                                            </span>
                                            <span>
                                                Students
                                            </span>
                                        </div>
                                    </div>
                                    : ''
                            }
                            {
                                exam_quantity ?
                                    <div className="state_item">
                                        <div className="state_item_item" title="Total Exam Quantity">
                                            <span>
                                                <i class="fas fa-pencil-ruler"></i> {exam_quantity}
                                            </span>
                                            <span className="state_item_item_title">
                                                Exam
                                            </span>
                                        </div>
                                    </div>
                                    : ''
                            }

                            {
                                video_quantity ?
                                    <div className="state_item">
                                        <div className="state_item_item" title="Total Video Quantity">
                                            <span>
                                                <i class="fas fa-video"></i> {video_quantity}
                                            </span>
                                            <span className="state_item_item_title">
                                                Video
                                            </span>
                                        </div>
                                    </div>
                                    : ''
                            }

                            {
                                pdf_quantity ?
                                    <div className="state_item">
                                        <div className="state_item_item" title="Total PDF Quantity">
                                            <span>
                                                <i class="far fa-file-pdf"></i> {pdf_quantity}
                                            </span>
                                            <span className="state_item_item_title">
                                                PDF
                                            </span>
                                        </div>
                                    </div>
                                    : ''
                            }

                            {
                                start_date ? <div className="state_item">
                                    <div className="state_item_item" title="Course Start Date">
                                        <span>
                                            <i class="far fa-clock"></i> {start_date}
                                        </span>
                                        <span className="state_item_item_title">
                                            Start Date
                                        </span>
                                    </div>
                                </div>
                                    : ''
                            }

                        </div>
                    </div>
                </div>
                <div className="container pb-3 mt-2">
                    <div className="course-card-bottom">
                        <div className="d-flex align-items-center justify-content-start">
                            {
                                course.instructors && course.instructors.length > 0 ? (
                                    course.instructors.map((instructor, index) => (

                                        <span className="instructor ms-1" key={index}>
                                            <img
                                                src={instructor.image}
                                                alt={instructor.name}
                                                title={instructor.name}
                                            />
                                        </span>

                                    ))
                                ) : null
                            }
                        </div>
                        <div className="d-flex justify-content-end  align-items-center pe-2 pt-md-0 pt-3">
                            {
                                offer_price > 0 ?
                                    <>
                                        <div className="course_base_price">
                                            <s>{price}</s>৳
                                        </div>
                                        <div className="course_price">
                                            {offer_price}৳
                                        </div>
                                    </> :
                                    <div className="course_price">
                                        {price}৳
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseItem;
